export enum LOCAL_STORAGE_KEYS {
    TOKEN = 'TOKEN'
};

export enum RESPONSE_CODES {
    SUCCESS = 1000,
    ERROR = 1305,
    UNAUTHORIZED = 401,
    //backend not confirmed about all the response codes
};

export enum NOTIFICATION_TYPE {
    EMAIL = "Email",
    SMS = "Sms",
    WHATSAPP = "Whatsapp",
};

export enum TICKET_TYPE {
    RAISE_TICKET = "RaiseTicket",
    CLOSE_TICKET = "CloseTicket",
    REOPEN_TICKET = "ReopenTicket",
};

export enum PARCEL_STATUS {
    HANDOVER = "HANDEDOVER",
    COLLECTED = "COLLECTED"
}

export enum PARCEL_PHOTO_STATUS {
    PARCELPHOTO = "parcelPhoto",
    HANDOVERPHOTO = "handedOverTo"
}

export enum QUICK_CONTACT_TYPE {
    AMBULANCE = "Ambulance",
    FIRE_BRIGADE = "Fire Brigade",
    HOSPITAL = "Hospital",
    POLICE = "Police",
    SOCIETY = "Society",
};

export enum QUICK_CONTACT_SORTING_CRITERIA {
    CONTACT_NAME = "Contact Name",
    TYPES = "Types"
}

export enum FILE_TYPES {
    PDF = 'pdf',
    JPG = 'jpg',
    JPEG = 'jpeg',
    AVIF = 'avif',
    PNG = 'png',
    CSV = 'csv',
    XLSX = 'xlsx',
    MP4 = 'mp4',
    MKV = 'mkv',
    AVI = 'avi',
    MOV = 'mov',
    MP3 = 'mp3',
    DOCX = 'docx',
    M4A = 'm4a',
}

export enum KYC_DOCUMENT_TYPE {
    AADHAAR = 'AADHAAR',
    PASSPORT = 'PASSPORT',
    PAN_CARD = 'PAN_CARD',
    VOTER_ID = 'VOTER_ID',
    DRIVING_LISCENSE = 'DRIVING_LISCENSE'
}

export enum SHIFT_NAMES {
    SHIFT_A = 'Shift A',
    SHIFT_B = 'Shift B',
    SHIFT_C = 'Shift C',
    GENERAL_SHIFT = 'General Shift'
}

export enum PERMISSIONS {
    SECURE_APP_ADMIN_ACCESS = 'Secure App Admin Access',
    SECURE_APP_GUARD_ACCESS = 'Secure App Guard Access',
    MANAGE_WEB_ADMIN_ACCESS = 'Manage Web Admin Access'
}

export enum VEHICLE_TYPES {
    BIKE = "BIKE",
    AUTO = "AUTO",
    CAR = "CAR",
    HEAVY = "HEAVY"
}
export enum VISITOR_STATUSES {
    RINGING = "RINGING",
    TAKE_ACTION = "TAKE_ACTION",
    ALLOWED = "ALLOWED",
    GUARD_ALLOWED = "GUARD_ALLOWED",
    DENIED = "DENIED",
    GUARD_DENIED = "GUARD_DENIED",
    COLLECT = "COLLECT",
    GUARD_COLLECTED = "GUARD_COLLECTED",
    LEFT = "LEFT",
    COLLECTED_AND_IN = "COLLECTED_AND_IN",
    PREAPPROVED = "PREAPPROVED"
}
export enum VISITOR_TYPE {
    GUEST = "GUEST",
    DELIVERY = "DELIVERY",
    CAB = "CAB",
    SERVICES = "SERVICES",
    HELPER = "HELPER",
    STAFF = "STAFF",
}

export enum GENDER {
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export enum PAYMENT_ACCOUNT_TYPE {
    UPI = "UPI",
    BANK_ACCOUNT = "Bank Account"
}

export enum DAYS {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday'
}

export enum MONTHS {
    JANUARY = 'January',
    FEBRUARY = 'February',
    MARCH = 'March',
    APRIL = 'April',
    MAY = 'May',
    JUNE = 'June',
    JULY = 'July',
    AUGUST = 'August',
    SEPTEMBER = 'September',
    OCTOBER = 'October',
    NOVEMBER = 'November',
    DECEMBER = 'December'
}

export enum DEVICE_TYPES {
    SOCIETY = 'SOCIETY',
    PERSONAL = 'PERSONAL'
}

export enum DEVICE_STATUS {
    LOCKED = 'LOCKED',
    UNLOCKED = 'UNLCOKED'
}

export enum CHECKPOINT_STATUS {
    YET_TO_SCAN = 'Yet To Scan',
    SCANNED = 'Scanned',
    SKIPPED = 'Location Skipped',
    LOCATION_MISMATCH = 'Location Mismatch'
}

export enum PATROL_LOG_STATUS {
    UPCOMING = 'Upcoming',
    START = 'Start',
    RESUME = 'Resume',
    IN_PROGRESS = 'In Progress',
    EXPIRED = 'Expired',
    COMPLETED = 'Completed',
    TIMEOUT = 'Time Out'
}

export enum FACING {
    EAST = 'East',
    WEST = 'West',
    NORTH = "North",
    SOUTH = "South",
    NORTH_EAST = "North East",
    SOUTH_EAST = "South East",
    NORTH_WEST = "North West",
    SOUTH_WEST = "South West"
}

export enum FLAT_SHARE {
    BUILDER = "Builder",
    LANDLORD = "Landlord"
}

export enum FLAT_STATUS {
    INACTIVE = "Inactive",
    HANDOVER = "Handover",
    EMPTY = "Empty",
    OWNER = "Owner",
    TENANT = "Tenant"
}

export enum CUSTOMER_TYPE {
    ORG = 'ORG',
    INDIVIDUAL = 'INDIVIDUAL',
    POC = 'POC',
    GPA = 'GPA',
    DRAFT = 'DRAFT',
}

export enum CITIZENSHIP_STATUS {
    INDIAN = 'Indian',
    NRI = 'NRI',
    FOREIGNER = 'Foreign Nationals'
}


export enum ORG_TYPE {
    REGISTERED_PARTNERSHIP = 'registered partnership',
    LIMITED_LIABILITY_PARTNERSHIP = 'limited liability partnership',
    COMPANY = 'company'
}

export enum ORG_RELATION {
    BOARD = 'board',
    PARTNERS = 'partners'
}

export enum ORG_IDENTITY_TYPE {
    SL_NO = 'slNo',
    LLP_IN = 'llpIn',
    CIN = 'cin'
}

export enum ASSIGNMENT_PREFERENCE {
    MANUALLY_ASSIGN = 'Manually assign at all times',
    AUTO_ASSIGN_WHEN_SUPERVISORS_UNAVAILABLE = 'Auto assign only when supervisors are unavailable',
    AUTO_ASSIGN_ALL_TICKETS = 'Auto assign all tickets'
}

export enum HELPDESK_TICKETS_STATUS {
    OPEN = 'Open',
    CLOSED = 'Closed'
}

export enum ASSET_STATUS {
    ACTIVE = 'Active',
    IN_REPAIR = 'In-Repair',
    NOT_IN_USE = 'Not in use',
    RETIRED = 'Retired'
}

export enum HELPDESK_TICKETS_CHAT_ACTIONS {
    TICKET_CREATED = 'Ticket Created',
    COMMENTED = 'Commented',
    TICKET_ASSIGNED = 'Ticket Assigned',
    MATERIALS_REQUIRED = 'Materials Required',
    RESIDENT_UNAVAILABLE = 'Resident Unavailable',
    APPROVAL_REQUIRED = 'Approval Required',
    TICKET_ESCALATED = 'Ticket Escalated',
    CATEGORY_UPDATED = 'Category Updated',
    TICKET_REASSIGNED = 'Ticket Re-Assigned',
    TICKET_CLOSED = 'Ticket Closed',
    TICKET_REOPENED = 'Ticket Re-Opened'
}

export enum HELPDESK_TICKETS_CHAT_SUB_ACTIONS {
    RESIDENT_TICKET_CREATED = 'Resident Ticket Created',
    STAFF_TICKET_CREATED = 'Staff Ticket Created',
    SYSTEM_COMMENTED = 'System Commented',
    RESIDENT_COMMENTED = 'Resident Commented',
    STAFF_COMMENTED = 'Staff Commented',
    SYSTEM_ASSIGNED = 'System Assigned',
    STAFF_ASSIGNED = 'Staff Assigned',
    RESIDENT_CLOSED = 'Resident Closed',
    ISSUE_RESOLVED = 'Issue Resolved',
    INVALID_TICKET = 'Invalid Ticket',
    ISSUE_NOT_FOUND = 'Issue Not Found',
    NOT_IN_SCOPE = 'Not In Scope',
    NO_RESPONSE_FROM_RESIDENT = 'No Response from Resident',
    AUTO_CLOSED = 'Auto Closed',
    RESIDENT_REOPENED = 'Resident Re-Opened',
    STAFF_REOPENED = 'Staff Re-Opened'
}

export enum HELPDESK_TICKETS_MESSAGE_TYPE {
    TEXT = 'Text',
    ATTACHMENT = 'Attachment'
}

export enum SHARED_SOCKET_EVENTS {
    JOIN_ROOM_EVENT = 'joinRoomEvent',
    LEAVE_ROOM_EVENT = 'leaveRoomEvent',
    NEW_HELPDESK_CHAT_MESSAGE_EVENT = 'newHelpdeskChatMessageEvent',
    HELPDESK_TICKET_STATUS_UPDATE = 'helpdeskTicketStatusUpdate'
}

export enum TURN_AROUND_TIME_TYPE {
    DAYS = 'days',
    HOURS = 'hours'
}

export enum SUB_WORK_PACKAGE_TYPES {
    TEXT = 'Text',
    OPTION = 'Option',
    NUMBER = 'Number'
}

export enum NAVIGATION_ROUTES {
    DASHBOARD = 'Dashboard',
    FLATS = 'Flats',
    HELP_DESK_SETUP = 'Helpdesk Setup',
    HELP_DESK_TICKETS = 'Helpdesk Tickets',
    STAFF_DIRECTORY = 'Staff Directory',
    HELPERS = 'Helpers',
    ROLES_AND_DEPARTMENTS = 'Roles & Departments',
    DEVICES = 'Devices',
    GATES = 'Gates',
    CHECKPOINTS = 'Checkpoints',
    PATROL_SHIFTS = 'Patrol Shifts',
    PATROL_LOGS = 'Patrol Logs',
    ACCOUNTS_AND_TRANSFERS = 'Accounts & Transfers',
    HEADERS = 'Headers',
    QUICK_CONTACTS = 'Quick Contacts',
    ACCESS_LOGS = 'Access Logs',
    PARCEL_LOGS = 'Parcel Logs',
    HELPDESK_SETTINGS = 'Helpdesk Settings',
    WORK_LOGS = 'Work Logs',
    WORK_PACKAGES = 'Work Packages',
    ASSETS = 'Assets',
    WORK_SCHEDULER = 'Work Scheduler',
    ISSUES = 'Issues',
}

export enum WORK_SCHEDULE_STATUS {
    UPCOMING = 'Upcoming',
    DEACTIVATED = 'Deactivated',
    DELETED = 'Deleted',
    PAUSED = 'Paused',
    COMPLETED = 'Completed',
    RUNNING = 'Running',
  }